import React from 'react';
import PropTypes from 'prop-types';
import OverlayElement from '../../app/components/content/OverlayElement';

const Logo = (props) => {
    return (
        <div {...props}>
                <svg xmlns="http://www.w3.org/2000/svg" width="156px" height="16mm" viewBox="391.5 291 57.5 9">
        
                <style type="text/css">
                {`.st0{fill:#023F88;}
                .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#023F88;}
                .st2{fill:#FF0000;}`}
                </style>
            <g>
                <polygon class="st0" points="440.34,299.55 441.75,299.55 441.75,297.85 442.29,297.3 443.71,299.55 445.41,299.55 443.3,296.4    445.19,294.45 443.52,294.45 441.75,296.34 441.75,292.48 440.33,292.48 440.34,299.55  "/>
                <path class="st1" d="M430.91,299.55v-2.44c0.4,0,0.8,0,0.8,0c1.71,0.03,3.06-0.45,3.06-2.34c0-1.54-1.19-2.36-2.91-2.29h-2.46v7.07   H430.91L430.91,299.55 M431.93,293.69c0.85-0.04,1.38,0.31,1.39,1.09c0.01,0.79-0.58,1.12-1.35,1.1h-1.05v-2.19H431.93   L431.93,293.69z"/>
                <path class="st1" d="M436.1,296.04c0,0-0.09-0.78,1.01-0.77c0.46,0.01,0.87,0.24,0.84,0.64c-0.02,0.3-0.27,0.37-0.76,0.45   c-0.81,0.1-1.38,0.23-1.76,0.42c-0.5,0.24-0.92,0.76-0.88,1.39c0.06,0.98,1.07,1.55,2.06,1.51c1.15-0.05,1.36-0.74,1.36-0.74   c0.02,0.11,0.02,0.11,0.04,0.27c0.03,0.23,0.08,0.33,0.08,0.33h1.45c-0.08-0.07-0.11-0.1-0.16-0.18c-0.05-0.12-0.06-0.22-0.06-0.34   v-2.92c0-1.44-1.15-1.8-2.29-1.82c-2.13-0.03-2.13,1.49-2.17,1.73l0,0L436.1,296.04L436.1,296.04 M436.93,298.66   c-0.43,0.04-0.99-0.08-1.01-0.62c-0.02-0.53,0.73-0.68,1.1-0.75c0.3-0.06,0.59-0.14,0.8-0.23c0.12-0.05,0.15-0.09,0.15-0.09v0.7   C437.95,298.19,437.59,298.59,436.93,298.66L436.93,298.66z"/>
                <polygon class="st0" points="408.72,299.55 408.72,293.73 410.88,293.73 410.88,292.47 405.09,292.47 405.09,293.73 407.22,293.73    407.22,299.55 408.72,299.55  "/>
                <path class="st1" d="M409.8,297.16c0.1,1.89,1.69,2.6,2.65,2.57c2.07-0.06,2.47-1.69,2.47-1.69l-1.45-0.08c0,0-0.23,0.65-1.03,0.65   c-1.22,0-1.25-1.22-1.25-1.22h3.78c0,0,0.14-3.04-2.66-3.08C410.72,294.27,409.72,295.61,409.8,297.16L409.8,297.16 M413.48,296.39   h-2.25c0,0,0.1-0.98,1.11-0.98C413.33,295.4,413.48,296.39,413.48,296.39L413.48,296.39z"/>
                <path class="st0" d="M418.94,299.55h1.41v-2.31c0-0.65,0.13-1.24,0.71-1.53c0.3-0.15,0.54-0.17,0.87-0.12v-1.21   c-0.05-0.03-0.21-0.04-0.37-0.05c-0.45-0.02-0.89,0.24-1.14,0.7c-0.03,0.04-0.04,0.07-0.08,0.17l-0.05-0.73h-1.35V299.55   L418.94,299.55z"/>
                <path class="st0" d="M415.07,295.43l0.7,0v2.6c0,0.42,0.04,0.95,0.45,1.25c0.4,0.3,0.95,0.26,1.47,0.26c0.22,0,0.44,0,0.44,0   l0-1.05h-0.33c-0.23,0-0.37-0.01-0.48-0.1c-0.18-0.14-0.17-0.36-0.17-0.48v-2.48h0.94v-0.96h-0.94v-1.61l-1.39,0v1.61l-0.7,0   V295.43L415.07,295.43z"/>
                <path class="st1" d="M423.49,296.04c0,0-0.09-0.78,1.01-0.77c0.46,0.01,0.87,0.24,0.84,0.64c-0.02,0.29-0.27,0.37-0.76,0.44   c-0.81,0.1-1.38,0.24-1.77,0.42c-0.5,0.24-0.92,0.76-0.88,1.39c0.06,0.98,1.07,1.55,2.06,1.51c1.15-0.05,1.36-0.74,1.36-0.74   c0.02,0.11,0.02,0.11,0.05,0.28c0.03,0.23,0.08,0.33,0.08,0.33h1.45c-0.08-0.07-0.11-0.1-0.16-0.18c-0.05-0.12-0.06-0.22-0.06-0.34   v-2.93c0-1.44-1.15-1.8-2.29-1.82c-2.13-0.03-2.13,1.49-2.18,1.73v0L423.49,296.04L423.49,296.04 M424.32,298.65   c-0.43,0.04-0.99-0.08-1.01-0.62c-0.02-0.53,0.73-0.68,1.11-0.76c0.31-0.06,0.59-0.14,0.79-0.23c0.12-0.05,0.15-0.09,0.15-0.09   v0.71C425.34,298.19,424.98,298.59,424.32,298.65L424.32,298.65z"/>
                <polygon class="st2" points="400.63,297.27 402.3,295.19 400.59,295.19 399.77,296.21 400.63,297.27  "/>
                <path class="st0" d="M397.54,296.23c0.33,0,0.62,0.15,0.83,0.39c0.08,0.09,0.1,0.11,0.15,0.19c0.2,0.25,0.27,0.34,0.38,0.49   c-0.12,0.14-0.18,0.24-0.38,0.49c-0.06,0.07-0.08,0.1-0.15,0.19c-0.21,0.25-0.49,0.39-0.83,0.39c-0.59,0-1.06-0.48-1.06-1.06   C396.47,296.71,396.95,296.23,397.54,296.23L397.54,296.23z"/>
                <path class="st0" d="M396.71,299.52c-0.21-0.05-0.4-0.16-0.47-0.2c-0.67-0.42-1.12-1.15-1.12-2.02c0-1.33,1.08-2.4,2.41-2.4   c0.65,0,1.31,0.25,1.83,0.86l0.03,0.04l0.02-0.02l-2.6-4.49l-4.78,8.26h4.67V299.52L396.71,299.52z"/>
                <path class="st0" d="M398.4,299.52v0.02h3.2v-0.02c0,0-0.05-0.01-0.1-0.01c-0.21-0.03-0.38-0.09-0.5-0.14   c-0.48-0.2-0.89-0.59-1.23-1c0,0-0.26,0.33-0.49,0.58C398.94,299.32,398.58,299.47,398.4,299.52L398.4,299.52z"/>
                <g>
                    <path class="st0" d="M447.07,291.15c0.92,0,1.66,0.75,1.66,1.66c0,0.91-0.75,1.66-1.66,1.66c-0.92,0-1.66-0.75-1.66-1.66    C445.41,291.89,446.15,291.15,447.07,291.15z M447.07,294.17c0.76,0,1.37-0.61,1.37-1.37c0-0.75-0.61-1.37-1.37-1.37    c-0.75,0-1.37,0.61-1.37,1.37C445.7,293.56,446.31,294.17,447.07,294.17z M446.43,291.85h0.73c0.46,0,0.67,0.21,0.67,0.57    c0,0.33-0.2,0.49-0.45,0.52l0.5,0.8h-0.37l-0.47-0.78h-0.25v0.78h-0.35V291.85z M446.78,292.67h0.25c0.21,0,0.45-0.01,0.45-0.27    s-0.24-0.27-0.45-0.26h-0.25V292.67z"/>
                </g>
            </g>
            </svg>
        </div>
    );
};
Logo.propTypes = {
    style: PropTypes.object
};
export default OverlayElement(Logo);
